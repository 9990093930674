<template>
  <div class="root">
    <el-card class="msg-card">
      <div slot="header" class="clearfix">
        <span>系统消息</span>
      </div>
      <el-table :data="msgData" stripe style="width: 100%">
        <el-table-column prop="msg" label="消息内容"> </el-table-column>
        <el-table-column prop="createTime" label="发送时间" width="200">
        </el-table-column>
        <el-table-column prop="messageTypeName" label="消息类型" width="80">
        </el-table-column>
      </el-table>
      <div v-if="total>size" class="pages">
        <el-pagination
          @current-change="currentChange"
          background
          layout="prev, pager, next"
          :total="total"
          :page-size="size"
          :current-page="page"
        >
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
import { portalManager_api } from "@/api/user.js";
import { sessionGet } from "@/utils/local.js";
export default {
  data() {
    return {
      msgData: [],
      page: 1, // 当前页码
      size: 10, // 每页条数
      total: 0, // 总条数
    };
  },
  methods: {
    // 获取通知消息
    async portalManager() {
      let { code, data } = await portalManager_api({
        userId: sessionGet("userId"),
        page: this.page,
        size: this.size,
      });
      if (code === 200) {
        this.msgData = data.list;
        this.total = data.pageCount;
      }
    },
    // 页码改变
    currentChange(page) {
      this.page = page
      this.portalManager()
    },
  },
  created() {
    this.portalManager();
  },
};
</script>

<style lang="less" scoped>
@title-color: #0079fe;
@btn-color: #f66866;
.root {
  .msg-card {
    margin-bottom: 50px;
    .clearfix span {
      font-weight: 700;
    }
    .pages {
      display:flex;
      justify-content: center;
      .el-pagination {
        margin-top: 20px;
      }
    }
  }
}
</style>